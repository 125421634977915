import React, { useEffect, useState, useMemo } from "react";
import { Box, Button, Grid, InputAdornment, TextField, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import Loader from "components/util/Loader";
// import { TeamSoreAndRank } from "store/themis_api_pb";
import { IError } from "../EventScheduleReport";
import { AltRoute, Clear as ClearIcon, ConstructionOutlined } from "@material-ui/icons";
import { getScoreByTeamID, getTeamScoreAndRankByUserLocation } from "store/user/userActions";
import { formateDate } from "lib/functions";
import themis_common, { Event } from "store/themis_common_pb";
import { ConstantData, DivisionInfo, EventWithTeamScore, RubricAreaJsonAndScore, RubricInfo, TeamScoreAndRankByUserLocationResponse } from "store/themis_api_pb";
import { TeamScoreAndDivision, TeamSoreAndRank } from "store/themis_api_pb";
interface ScoreSheetProps {
  routeProps?: RouteComponentProps<{
    eventId: string;
  } & {
    teamId: string;
  }>;
  showFilters: boolean;
  enableRouting?: boolean;
  teamId?: number;
  currentLocation?: themis_common.Location.AsObject;
  program?: themis_common.Program.AsObject;
}
export interface IFilters {
  eventName: string;
  divisionName: string;
  teamName: string;
}
const INITIAL_FILTERS: IFilters = {
  divisionName: "",
  eventName: "",
  teamName: ""
};
interface IData {
  event: EventWithTeamScore.AsObject | undefined;
}
interface IDataWithTeamScore extends IData {
  event: IData["event"] & {
    teamScoreEventDivisionList: TeamScoreAndDivision.AsObject[];
  };
}
const MESSAGES = {
  notFound: "Record Not Found!",
  loading: "Loading Score Sheet Report",
  filters: "No data match your current filter",
  notFoundRubric: "Score sheet data was not found"
};
const FILTER_PLACEHOLDER = {
  eventName: "Filter By Event Name",
  divisionName: "Filter By Division Name",
  teamName: "Filter By Team Name"
};
const CONTAINER_STYLE = {
  border: "2px solid",
  width: "100%",
  height: "100%",
  minHeight: "155px",
  boxSizing: "border-box",
  overflow: "hidden"
};
const RUBRIC_AREA_CONTAINER_STYLE = {
  border: "2px solid",
  width: "100%",
  minHeight: "160px",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  overflow: "hidden"
};
const RUBRIC_STYLE = {
  width: "100%",
  height: "50px",
  marginTop: "12px",
  background: "black",
  color: "white",
  textAlign: "center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
};
const FONT_WEIGHT_STYLE = {
  bold: "bold",
  600: 600
};
const LOADING_OR_ERROR_CONTENT_STYLE = {
  display: "flex",
  justifyContent: "center",
  marginTop: "300px",
  alignItems: "center"
};
const LARGE_CONTENT_XS = 10;
const IMAGE_XS = 2;
const HEADER_TEXT_CONTENT_FONT_SIZE = "20px";
const DEFAULT_IMAGE = "/themislogo.png";
const PRIMARY_COLOR = "#b30808";
const EVENT_NAME_FONT_SIZE = "23px";
const EMPTY_STRING = "";
const LOADING_MILL_SECOND = 1000;
const calculateTeamTemplate4Rating = (rubricAreaInfo: RubricAreaJsonAndScore.AsObject[]): string => {
  try {
    // Track totals across all sections
    let totalHigh = 0;
    let totalAverage = 0;
    let totalLow = 0;

    // Process all scores
    rubricAreaInfo.forEach(item => {
      if (!item.scoreDynamicJson) return;
      const data = JSON.parse(item.scoreDynamicJson);
      const sectionKey = Object.keys(data)[0];
      const sectionData = data[sectionKey];
      if (sectionData?.ratings?.radioValues) {
        // Count checkmarks in each column
        sectionData.ratings.radioValues.forEach((row: any) => {
          const column = row.value.split('_&_')[1];
          if (column === 'High') totalHigh++;else if (column === 'Average') totalAverage++;else if (column === 'Low') totalLow++;
        });
      }
    });
    if (totalHigh > totalAverage && totalHigh > totalLow) {
      return 'Superior';
    } else if (totalAverage > totalHigh && totalAverage > totalLow) {
      return 'Excellent';
    } else if (totalLow > totalHigh && totalLow > totalAverage) {
      return 'Outstanding';
    } else if (totalHigh === totalAverage && totalHigh > totalLow) {
      return 'Superior'; // If tied between High and Average, give higher rating
    } else if (totalAverage === totalLow && totalAverage > totalHigh) {
      return 'Excellent'; // If tied between Average and Low, give higher rating
    } else if (totalHigh === totalLow && totalHigh > totalAverage) {
      return 'Superior'; // If tied between High and Low, give higher rating
    }

    // If all equal or no data
    return totalHigh + totalAverage + totalLow > 0 ? 'Excellent' : '';
  } catch (e) {
    console.error('Failed to calculate team template-4 rating:', e);
    return '';
  }
};
const renderTemplate4Table = (scoreDynamicJson: string, comment?: string) => {
  try {
    const data = JSON.parse(scoreDynamicJson);
    const sectionKey = Object.keys(data)[0];
    const sectionData = data[sectionKey];
    if (!sectionData?.ratings?.radioValues) return null;
    const formatSectionTitle = (key: string) => {
      return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()).trim();
    };
    const rowValues = sectionData.ratings.radioValues.reduce((acc: Record<string, string>, row: any) => {
      const [label] = row.value.split('_&_');
      const column = row.value.split('_&_')[1];
      acc[label] = column;
      return acc;
    }, {});
    const columnTotals = {
      Low: 0,
      Average: 0,
      High: 0
    };
    Object.values(rowValues).forEach(column => {
      if (column in columnTotals) {
        columnTotals[column as keyof typeof columnTotals]++;
      }
    });
    return <Box sx={{
      marginY: 2
    }}>
        <Typography variant="h6" sx={{
        marginBottom: 1,
        fontWeight: 'bold'
      }}>
          {formatSectionTitle(sectionKey)}
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Criteria</TableCell>
                <TableCell align="center">Low</TableCell>
                <TableCell align="center">Average</TableCell>
                <TableCell align="center">High</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sectionData.ratings.radioValues.map((row: any) => {
              const [label, column] = row.value.split('_&_');
              return <TableRow key={label}>
                    <TableCell>{label}</TableCell>
                    <TableCell align="center">{column === 'Low' ? '✓' : ''}</TableCell>
                    <TableCell align="center">{column === 'Average' ? '✓' : ''}</TableCell>
                    <TableCell align="center">{column === 'High' ? '✓' : ''}</TableCell>
                  </TableRow>;
            })}
              <TableRow sx={{
              backgroundColor: '#f5f5f5'
            }}>
                <TableCell sx={{
                fontWeight: 'bold'
              }}>Total Checkmarks</TableCell>
                <TableCell align="center" sx={{
                fontWeight: 'bold'
              }}>{columnTotals.Low}</TableCell>
                <TableCell align="center" sx={{
                fontWeight: 'bold'
              }}>{columnTotals.Average}</TableCell>
                <TableCell align="center" sx={{
                fontWeight: 'bold'
              }}>{columnTotals.High}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {comment && <Typography variant="body2" sx={{
        marginTop: 1
      }}>
            Comment: {comment}
          </Typography>}
      </Box>;
  } catch (e) {
    console.error('Failed to parse template-4 data:', e);
    return null;
  }
};
const renderTemplate3Data = (scoreDynamicJson: string, dynamicJson: string) => {
  try {
    const data = JSON.parse(scoreDynamicJson);
    const dynamicData = JSON.parse(dynamicJson);
    const category = Object.keys(data)[0]; // 'legalities' or 'deductions'
    const items = data[category]?.data || [];
    if (items.length === 0) return null;
    return <div style={{
      marginTop: "10px",
      marginLeft: "15px"
    }}>
        {items.map((item: any, index: number) => {
        const displayLabel = item[`${category}Object`]?.label || item[category] || category;
        console.log('displayLabel', displayLabel);
        const rawValue = item[`${category}Object`]?.value || '';
        const displayValue = rawValue ? Number(rawValue) : '';
        const comment = item.comment;
        return <div key={index} style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: "5px",
          paddingRight: "15px"
        }}>
              <Typography>
                • {displayLabel} {comment && `- ${comment}`}
                {item.timestamp && ` | Time: ${item.timestamp}`}
              </Typography>
              <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            minWidth: '100px' // Ensures consistent width for alignment
          }}>
                {displayValue && <>
                    <Typography style={{
                borderBottom: '1px solid #666'
              }}>
                      -{displayValue}{item.multiplier && item.multiplier !== 1 ? ` × ${item.multiplier}` : ''}
                    </Typography>
                    {item.multiplier && item.multiplier !== 1 && <Typography>
                        = {parseFloat(-displayValue) * item.multiplier}
                      </Typography>}
                  </>}
              </div>
            </div>;
      })}
      </div>;
  } catch (e) {
    console.error('Failed to parse template-3 data:', e);
    return null;
  }
};
const renderRubricContent = (rubricData: RubricAreaJsonAndScore.AsObject[] | undefined) => {
  if (!rubricData) return null;
  const groupedByJudgeType = new Map<string, {
    categories: Map<string, {
      score: number;
      title: string;
      comments: string[];
      template3Data?: JSX.Element | null;
    }>;
    totalScore: number;
  }>();
  const template4Items: JSX.Element[] = [];
  rubricData.forEach(({
    dynamicJson: stringifyDynamicJson,
    score,
    comment,
    scoreDynamicJson
  }) => {
    try {
      const dynamicJsonObj = JSON.parse(stringifyDynamicJson);
      const isTemplate4 = dynamicJsonObj[0]?.data?.items?.[0]?.templateName === 'template-4';
      const isTemplate3 = dynamicJsonObj[0]?.data?.items?.[0]?.templateName === 'template-3';
      if (isTemplate4 && scoreDynamicJson) {
        const template4Element = renderTemplate4Table(scoreDynamicJson, comment);
        if (template4Element) {
          template4Items.push(template4Element);
        }
        return;
      }
      Object.keys(dynamicJsonObj).forEach(objectKey => {
        const items = dynamicJsonObj[objectKey]?.data?.items || [];
        const judgeType = items[0]?.judgeType || 'Unknown Judge Type';
        const flatArray = items.map((item: any) => item.data.rows).flat()?.[0]?.[0];
        const staticLabelTitle = flatArray?.find((row: any) => row.type === "staticLabel")?.title;
        if (staticLabelTitle) {
          if (!groupedByJudgeType.has(judgeType)) {
            groupedByJudgeType.set(judgeType, {
              categories: new Map(),
              totalScore: 0
            });
          }
          const judgeGroup = groupedByJudgeType.get(judgeType)!;
          const categoryName = staticLabelTitle.split('\n')[0].trim();
          if (!judgeGroup.categories.has(categoryName)) {
            judgeGroup.categories.set(categoryName, {
              score: 0,
              title: staticLabelTitle,
              comments: [],
              template3Data: isTemplate3 && scoreDynamicJson ? renderTemplate3Data(scoreDynamicJson, stringifyDynamicJson) : null
            });
          }
          const category = judgeGroup.categories.get(categoryName)!;
          category.score += Number(score);
          if (comment) {
            category.comments.push(comment);
          }
          judgeGroup.totalScore += Number(score);
        }
      });
    } catch (e) {
      console.error('Failed to parse rubric data:', e);
    }
  });
  return <>
      {template4Items}
      {Array.from(groupedByJudgeType.entries()).map(([judgeType, group], index) => <Box key={`judge_type_${index}`}>
          <Typography variant="h6" style={{
        marginTop: '20px',
        marginBottom: '10px',
        marginLeft: '10px',
        fontWeight: 'bold'
      }}>
            {judgeType} (Total Score: {group.totalScore.toFixed(3)})
          </Typography>
          {Array.from(group.categories.entries()).map(([categoryName, category], catIndex) => <Box key={`category_${catIndex}`} style={{
        border: "1px solid white",
        padding: "10px",
        marginBottom: "10px",
        fontWeight: FONT_WEIGHT_STYLE[600],
        marginLeft: "20px",
        width: "95%",
        wordBreak: "break-word",
        '@media print': {
          breakInside: 'avoid',
          pageBreakInside: 'avoid'
        }
      }}>
              <Typography>
                {category.title}
              </Typography>
              {category.template3Data}
              {category.comments.length > 0 && <Typography style={{
          marginTop: '8px'
        }}>
                  Comments:<br />
                  {category.comments.map((comment, i) => <span key={i}>• {comment}<br /></span>)}
                </Typography>}
              <Typography style={{
          marginTop: '8px'
        }}>
                Score: {category.score.toFixed(1)}
              </Typography>
            </Box>)}
        </Box>)}
    </>;
};
const calculateTotalScore = (rubricInfoList: RubricInfo.AsObject[]): number => {
  if (!rubricInfoList || !Array.isArray(rubricInfoList)) {
    return 0;
  }
  return rubricInfoList.reduce((totalScore, rubric) => {
    if (!rubric?.rubricareainfoList) {
      return totalScore;
    }
    const rubricAreaScores = rubric.rubricareainfoList.reduce((areaTotal, area) => {
      if (!area?.rubricareajsonandscoreList) {
        return areaTotal;
      }
      const areaScore = area.rubricareajsonandscoreList.reduce((score, item) => {
        const numScore = Number(item?.score || 0);
        return score + (isNaN(numScore) ? 0 : numScore);
      }, 0);
      return areaTotal + areaScore;
    }, 0);
    return totalScore + rubricAreaScores;
  }, 0);
};
const ScoreSheet: React.FC<ScoreSheetProps> = ({
  routeProps,
  showFilters,
  currentLocation,
  program,
  enableRouting,
  teamId
}) => {
  // Extract routing params ids
  const eventDivisionTeam = routeProps?.match.params.teamId ?? undefined;
  const eventId = routeProps?.match.params.eventId ?? undefined;

  //
  const programUserId = program?.id ? program?.id : undefined;
  const currentLocationId = currentLocation?.id ? currentLocation?.id : undefined;

  // State Variables
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(EMPTY_STRING);
  const [filterParams, setFilterParams] = useState<IFilters>(INITIAL_FILTERS);
  const [eventParameters, setEventParameters] = useState<{
    eventName: string;
  }>();
  const [teamScoreInfo, setTeamScoreInfo] = useState<ConstantData.AsObject | undefined>();
  const [filteredEvents, setFilteredEvents] = useState<IDataWithTeamScore[]>();
  const [filteredData, setFilteredData] = useState<IDataWithTeamScore[] | undefined>();
  const [userLocationTeamScoreInfo, setUserLocationTeamScoreInfo] = useState<IDataWithTeamScore[] | undefined>();
  const [userLocationTeamScore, setUserLocationTeamScore] = useState<IDataWithTeamScore[]>();

  // State Variables
  const [teamTotalScores, setTeamTotalScores] = useState<Map<number, number>>(new Map());

  // Calculate total scores when teamScoreInfo changes
  useEffect(() => {
    if (teamScoreInfo?.divisioninfo?.teamsinfoList) {
      const scores = new Map(teamScoreInfo.divisioninfo.teamsinfoList.map((teamInfo, index) => [index, calculateTotalScore(teamInfo.rubricinfoList)]));
      setTeamTotalScores(scores);
      console.log("Updated teamTotalScores:", scores);
    }
  }, [teamScoreInfo]);

  // Fetch UserLocationTeamScoreInfo Details
  useEffect(() => {
    const fetchUserLocationTeamScoreInfo = async () => {
      setLoading(true);
      try {
        const userId = programUserId;
        const locationId = currentLocationId;
        const eventTeamId = teamId ? teamId : 0;
        const response = await getTeamScoreAndRankByUserLocation({
          locationId,
          teamId: eventTeamId,
          UUID: userId as number
        });
        const hasUserLocationTeamScoreInfo = response.responseList.length;
        const message = !hasUserLocationTeamScoreInfo ? MESSAGES.notFound : EMPTY_STRING;
        handelErrorMessage(message);
        const transformEventData = (response: TeamScoreAndRankByUserLocationResponse.AsObject) => {
          const flattenedTeamScoreInfo = response.responseList.flatMap(({
            event
          }) => event?.eventDateListList.flatMap(({
            teamScoreEventDivisionList
          }) => teamScoreEventDivisionList));
          const modifiedEvents = response.responseList.map(({
            event
          }) => ({
            event: {
              ...event,
              teamScoreEventDivisionList: flattenedTeamScoreInfo
            }
          }));
          return modifiedEvents;
        };
        // Example usage:
        const transformedData = transformEventData(response);
        const responseList = transformedData as unknown as IDataWithTeamScore[] | undefined;
        setUserLocationTeamScoreInfo(responseList);
        setUserLocationTeamScore(responseList);
        setFilteredData(responseList);
        setFilteredEvents(responseList);
      } catch (error) {
        const err = error as IError;
        handelErrorMessage(err.message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, LOADING_MILL_SECOND);
      }
    };
    if (!eventId) {
      fetchUserLocationTeamScoreInfo();
    }
  }, [eventId, teamId, currentLocation, program]);

  // Fetch TeamScoreInfo Details
  useEffect(() => {
    const areRequiredIds = eventId !== undefined && eventDivisionTeam !== undefined;
    if (areRequiredIds) {
      const fetchTeamScoreInfo = async () => {
        setLoading(true);
        try {
          const response = await getScoreByTeamID({
            eventId: Number(eventId),
            teamId: Number(eventDivisionTeam)
          });
          console.log("getScoreByTeamID", response);
          const {
            constantData
          } = response;
          setTeamScoreInfo(constantData);
          console.log("CONSTANT DATA STRING", JSON.stringify(constantData));
        } catch (error) {
          const err = error as IError;
          const {
            message
          } = err;
          handelErrorMessage(message);
        } finally {
          setTimeout(() => {
            setLoading(false);
          }, LOADING_MILL_SECOND);
        }
      };
      fetchTeamScoreInfo();
    }
  }, [eventId, eventDivisionTeam, currentLocation, program]);
  useEffect(() => {
    handleFilters();
  }, [filterParams]);
  useEffect(() => {
    handelEventFilter();
  }, [eventParameters]);

  // Filter's for teamScoreAndRankByUserLocation score sheet
  const filterEventsByEventName = (events: IDataWithTeamScore[], eventName: string) => eventName && eventName.length ? events.filter(eventInfo => eventInfo.event && eventInfo.event.name && eventInfo.event.name.toLowerCase().includes(eventName.toLowerCase())) : events;

  // Filter by division name
  const filterEventsByDivisionName = (events: IDataWithTeamScore[], divisionName: string) => divisionName && divisionName.length ? events.filter(eventInfo => eventInfo.event && eventInfo.event.teamScoreEventDivisionList && eventInfo.event.teamScoreEventDivisionList.some(({
    division,
    teamScoreRankList
  }) => {
    if (teamScoreRankList.length) {
      return division?.name.toLowerCase().includes(divisionName.toLowerCase());
    }
  })) : events;

  // Filter by team name
  const filterTeamNames = (teamScoreRankList: TeamSoreAndRank.AsObject[], teamName: string) => teamScoreRankList.filter(({
    teamName: rankTeamName
  }) => rankTeamName.toLowerCase().includes(teamName.toLowerCase()));

  // Filter by division team name
  const filterEventsByTeamName = (events: IDataWithTeamScore[], teamName: string) => teamName && teamName.length ? events.map(eventInfo => {
    if (!eventInfo.event || !eventInfo.event.teamScoreEventDivisionList) return eventInfo;
    const updatedEvent = {
      ...eventInfo.event
    };
    updatedEvent.teamScoreEventDivisionList = eventInfo.event.teamScoreEventDivisionList.map(({
      teamScoreRankList,
      ...division
    }) => ({
      ...division,
      teamScoreRankList: filterTeamNames(teamScoreRankList, teamName)
    }));
    return {
      ...eventInfo,
      event: updatedEvent
    };
  }) : events;
  const appliedFilters = (events: IDataWithTeamScore[] | undefined, filters = INITIAL_FILTERS) => {
    const {
      eventName,
      divisionName,
      teamName
    } = filters;
    if (!events) return []; // If events are not provided, return an empty array

    let filteredData = filterEventsByEventName(events, eventName);
    filteredData = filterEventsByDivisionName(filteredData, divisionName);
    filteredData = filterEventsByTeamName(filteredData, teamName);
    return filteredData;
  };

  // Filters based on the filter's parameters
  const handleFilters = () => {
    const filteredData = appliedFilters(userLocationTeamScoreInfo, filterParams);
    setFilteredData(filteredData);
  };

  // Filter for only event name
  const filterEventsByEvent = (events: IDataWithTeamScore[] | undefined, eventName: string | undefined) => {
    if (!eventName) {
      return events;
    }
    return events?.filter(({
      event
    }) => event?.name.toLowerCase().includes(eventName?.toLowerCase()));
  };
  const handelEventFilter = () => {
    const filteredEvents = filterEventsByEvent(userLocationTeamScore ?? [], eventParameters?.eventName);
    const events = eventParameters?.eventName ? filteredEvents : userLocationTeamScore;
    setFilteredEvents(events);
  };

  // Function to highlighted filtered text
  const highlightedText = (text: string, highlight: string, fontSize: string) => {
    if (!text || !highlight) {
      return text;
    } else {
      const lowerText = text.toLowerCase();
      const lowerHighlight = highlight.toLowerCase();
      if (lowerText.includes(lowerHighlight)) {
        const parts = text.split(new RegExp(`(${highlight})`, "gi"));
        return parts.map((part, index) => {
          const matchedText = part.toLowerCase() === highlight.toLowerCase();
          return matchedText ? <Typography key={index} variant={"inherit"} style={{
            color: PRIMARY_COLOR,
            fontWeight: "bold",
            fontSize
          }}>
              {part}
            </Typography> : part;
        });
      }
    }
    return text;
  };

  // Function to handel error message
  const handelErrorMessage = (error: string) => {
    return setErrorMessage(error);
  };

  // Function to handel render filter's content
  const renderFilters = () => {
    const handelFilters = (eventName: string, divisionName: string, teamName: string) => {
      const hasFilters = eventName.length || divisionName.length || teamName.length;
      if (hasFilters) {
        setFilterParams(() => ({
          ...filterParams,
          eventName,
          divisionName,
          teamName
        }));
      }
    };
    const clearFilters = (eventName: string, divisionName: string, teamName: string) => {
      setFilterParams(() => ({
        ...filterParams,
        eventName,
        divisionName,
        teamName
      }));
    };
    return showFilters ? <Grid container style={{
      marginBottom: "20px",
      marginLeft: "13px"
    }}>
        <Grid item xs={3}>
          <TextField variant="outlined" margin="normal" fullWidth label={FILTER_PLACEHOLDER.eventName} value={filterParams.eventName} style={{
          width: "350px"
        }} onChange={e => {
          const searchValue = e.target.value;
          const {
            teamName,
            divisionName
          } = filterParams;
          handelFilters(searchValue, divisionName, teamName);
        }} InputProps={{
          endAdornment: <InputAdornment position="end">
                  {filterParams.eventName ? <ClearIcon style={{
              cursor: "pointer"
            }} onClick={() => {
              const removeSearch = EMPTY_STRING;
              const {
                teamName,
                divisionName
              } = filterParams;
              clearFilters(removeSearch, divisionName, teamName);
            }} /> : null}{" "}
                </InputAdornment>
        }} />
        </Grid>
        <Grid item xs={3}>
          <TextField variant="outlined" margin="normal" fullWidth label={FILTER_PLACEHOLDER.divisionName} value={filterParams.divisionName} style={{
          width: "350px"
        }} onChange={e => {
          const searchValue = e.target.value;
          const {
            eventName,
            teamName
          } = filterParams;
          handelFilters(eventName, searchValue, teamName);
        }} InputProps={{
          endAdornment: <InputAdornment position="end">
                  {filterParams.divisionName ? <ClearIcon style={{
              cursor: "pointer"
            }} onClick={() => {
              const removeSearch = EMPTY_STRING;
              const {
                eventName,
                teamName
              } = filterParams;
              clearFilters(eventName, removeSearch, teamName);
            }} /> : null}{" "}
                </InputAdornment>
        }} />
        </Grid>
        <Grid item xs={3}>
          <TextField variant="outlined" margin="normal" fullWidth label={FILTER_PLACEHOLDER.teamName} value={filterParams.teamName} style={{
          width: "350px"
        }} onChange={e => {
          const searchValue = e.target.value;
          const {
            divisionName,
            eventName
          } = filterParams;
          handelFilters(eventName, divisionName, searchValue);
        }} InputProps={{
          endAdornment: <InputAdornment position="end">
                  {filterParams.teamName ? <ClearIcon style={{
              cursor: "pointer"
            }} onClick={() => {
              const removeSearch = EMPTY_STRING;
              const {
                divisionName,
                eventName
              } = filterParams;
              clearFilters(eventName, divisionName, removeSearch);
            }} /> : null}{" "}
                </InputAdornment>
        }} />
        </Grid>
      </Grid> : null;
  };

  // Function to render event's filter
  const renderEventFilter = () => {
    return teamId ? <Grid container style={{
      marginBottom: "20px",
      marginLeft: "13px"
    }}>
        <Grid item xs={3}>
          <TextField variant="outlined" margin="normal" fullWidth label={FILTER_PLACEHOLDER.eventName} value={eventParameters?.eventName} style={{
          width: "350px"
        }} onChange={e => {
          const searchValue = e.target.value;
          setEventParameters({
            ...eventParameters,
            eventName: searchValue
          });
        }} InputProps={{
          endAdornment: <InputAdornment position="end">
                  {eventParameters?.eventName ? <ClearIcon style={{
              cursor: "pointer"
            }} onClick={() => {
              const removeSearch = EMPTY_STRING;
              setEventParameters({
                ...eventParameters,
                eventName: removeSearch
              });
            }} /> : null}{" "}
                </InputAdornment>
        }} />
        </Grid>
      </Grid> : null;
  };

  // Function to handel redirect routing
  const handelRouting = (eventId: number, teamId: number) => {
    if (eventId !== undefined && teamId !== undefined) {
      const redirectURL = `score_sheet/event/${eventId}/team/${teamId}`;
      window.open(redirectURL);
    }
  };

  // Function to render header content
  const renderHeader = (event: Event.AsObject | EventWithTeamScore.AsObject, divisionInfo: TeamScoreAndDivision.AsObject | DivisionInfo.AsObject | undefined, teamInfo: TeamSoreAndRank.AsObject | any, isFirstRubric: boolean = true) => {
    // Only render the header if it's the first rubric
    if (!isFirstRubric) return null;

    // Improved template-4 detection logic
    const isTemplate4 = teamInfo.rubricinfoList?.some((rubric: any) => rubric.rubricareainfoList?.some((area: any) => area.rubricareajsonandscoreList?.some((item: any) => {
      try {
        const dynamicJson = JSON.parse(item.dynamicJson);
        return dynamicJson[0]?.data?.items?.[0]?.templateName === 'template-4';
      } catch {
        return false;
      }
    })));
    console.log("isTemplate4", isTemplate4);
    if (isTemplate4) {
      // Calculate rating for template-4
      const allRubricAreas = teamInfo.rubricinfoList?.flatMap((rubric: any) => rubric.rubricareainfoList?.flatMap((area: any) => area.rubricareajsonandscoreList || []) || []) || [];
      const rating = calculateTeamTemplate4Rating(allRubricAreas);
      return <div style={{
        marginBottom: '20px'
      }}>
          <Typography variant="h5" style={{
          textAlign: "center",
          marginBottom: "10px",
          fontWeight: "bold"
        }}>
            Performance Recap
          </Typography>
          
          <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          border: "1px solid black"
        }}>
            <div>
              <Typography><strong>Event:</strong> {event?.brand?.name ?? 'N/A'} - {event?.name ?? 'N/A'}</Typography>
              <Typography><strong>Team:</strong> {teamInfo.programName} - {teamInfo.name || teamInfo.teamName}</Typography>
              <Typography><strong>Division:</strong> {divisionInfo?.division?.name ?? ''}</Typography>
              <Typography><strong>Rating:</strong> {rating}</Typography>
            </div>
            <img src={event?.logo || DEFAULT_IMAGE} alt="Event Logo" style={{
            height: "80px"
          }} />
          </div>
        </div>;
    }

    // Original header for non-template-4
    const divisionTeamInfo = teamInfo as TeamSoreAndRank.AsObject;
    const isVisibleScoreSheetButton = !enableRouting || teamId;
    const eventLogo = event?.logo ? event?.logo : DEFAULT_IMAGE;
    const hasRubric = teamScoreInfo?.divisioninfo?.teamsinfoList.some(({
      rubricinfoList
    }) => rubricinfoList.length);
    const teamName = divisionTeamInfo.teamName ? divisionTeamInfo.teamName : teamInfo.name ? teamInfo.name : " ";
    const programName = divisionTeamInfo.programName ?? EMPTY_STRING;
    const divisionName = divisionInfo?.division?.name ?? EMPTY_STRING;
    const param = teamId ? eventParameters?.eventName : filterParams.eventName;
    return <Grid container style={CONTAINER_STYLE}>
        <Grid item xs={LARGE_CONTENT_XS}>
          <Typography style={{
          fontWeight: "bold",
          fontSize: EVENT_NAME_FONT_SIZE,
          marginLeft: "10px",
          marginTop: "10px"
        }}>
            {highlightedText(event.name ?? EMPTY_STRING, param ?? EMPTY_STRING, EVENT_NAME_FONT_SIZE)}{" "}
            {formateDate(event?.firstDay ?? EMPTY_STRING)}
          </Typography>
          <Grid style={{
          marginLeft: "10px",
          marginTop: "10px"
        }}>
            <Typography style={{
            fontWeight: FONT_WEIGHT_STYLE[600],
            fontSize: HEADER_TEXT_CONTENT_FONT_SIZE
          }}>
              Team: {}
              {highlightedText(programName + " " + teamName ?? EMPTY_STRING, filterParams.teamName, HEADER_TEXT_CONTENT_FONT_SIZE)}{" "}
            </Typography>
            <Typography style={{
            marginTop: "10px",
            fontWeight: FONT_WEIGHT_STYLE[600],
            fontSize: HEADER_TEXT_CONTENT_FONT_SIZE
          }}>
              Division:{}
              {highlightedText(divisionName ?? EMPTY_STRING, filterParams.divisionName, HEADER_TEXT_CONTENT_FONT_SIZE)}{" "}
            </Typography>
            {isVisibleScoreSheetButton ? <Button color="primary" variant="contained" size="small" style={{
            marginTop: "10px",
            marginBottom: "10px"
          }} onClick={() => {
            const eventId = event.id ?? 0;
            const teamId = divisionTeamInfo.teamId ?? 0;
            handelRouting(eventId, teamId);
          }}>
                View Score Sheet
              </Button> : null}
          </Grid>
        </Grid>
        <Grid item xs={IMAGE_XS} style={{
        display: "flex",
        alignItems: "center"
      }}>
          <img src={eventLogo} alt="Event Logo" style={{
          height: "100px"
        }} />
        </Grid>
      </Grid>;
  };

  // Function to render rubricArea content
  const renderRubricArea = (rubricAreaName?: string, rubricAreaInfo?: RubricAreaJsonAndScore.AsObject[], rubricAreaIndex?: number) => {
    if (!rubricAreaInfo) return null;

    // Create a map to store unique items by their type
    const uniqueItems = new Map();

    // Sort and deduplicate rubricAreaInfo
    const categoryTotals = new Map<string, number>();
    rubricAreaInfo.forEach(item => {
      try {
        const dynamicJsonObj = JSON.parse(item.dynamicJson);
        const scoreDynamicObj = item.scoreDynamicJson ? JSON.parse(item.scoreDynamicJson) : null;
        const itemType = Object.keys(scoreDynamicObj || {})[0]; // Get the item type
        const currentScore = parseFloat(item.score) || 0;

        // Check if this is a template-3 item
        const isTemplate3 = dynamicJsonObj[0]?.data?.items?.[0]?.templateName === 'template-3';
        if (isTemplate3) {
          // Update category total
          categoryTotals.set(itemType, (categoryTotals.get(itemType) || 0) + currentScore);
          const existingItems = uniqueItems.get(itemType) || [];
          const items = Array.isArray(existingItems) ? existingItems : [existingItems];

          // Check if this specific deduction already exists
          const isDuplicate = items.some(existing => existing.scoreDynamicJson === item.scoreDynamicJson);
          if (!isDuplicate && currentScore !== 0) {
            // Only add non-zero score items that aren't duplicates
            items.push(item);
            uniqueItems.set(itemType, items);
          }
        } else {
          // For non-template-3 items
          const existingItem = uniqueItems.get(itemType);
          if (!existingItem || currentScore !== 0) {
            uniqueItems.set(itemType, item);
          }
        }
      } catch (e) {
        console.error('Failed to parse item JSON:', e);
      }
    });

    // When converting back to array, handle template-3 arrays and update their scores
    const dedupedRubricAreaInfo = Array.from(uniqueItems.entries()).flatMap(([itemType, items]) => {
      if (Array.isArray(items)) {
        // For template-3 items, use the accumulated category total
        const totalScore = categoryTotals.get(itemType) || 0;
        return items.map((item, index) => ({
          ...item,
          // Only show the total score on the first item of the category
          score: index === 0 ? totalScore.toString() : "0"
        }));
      }
      return [items];
    }).sort((a, b) => a.itemOrder - b.itemOrder);

    // Calculate total points for this rubric area
    const totalPoints = dedupedRubricAreaInfo.reduce((sum, item) => {
      const score = parseFloat(item.score) || 0;
      return sum + score;
    }, 0);
    const isTemplate3 = (dynamicJson: string) => {
      try {
        const data = JSON.parse(dynamicJson);
        return data[0]?.data?.items?.[0]?.templateName === 'template-3';
      } catch {
        return false;
      }
    };
    return <div style={{
      marginBottom: "30px"
    }}>
        <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid #000",
        paddingBottom: "5px",
        marginBottom: "15px"
      }}>
          <Typography variant="h6" style={{
          fontWeight: "bold"
        }}>
            {rubricAreaName}
          </Typography>
          <Typography variant="h6" style={{
          fontWeight: "bold"
        }}>
            Section Total: {totalPoints.toFixed(1)}
          </Typography>
        </div>
        
        {dedupedRubricAreaInfo.map((item, index) => {
        try {
          const dynamicJsonObj = JSON.parse(item.dynamicJson);
          const items = dynamicJsonObj[0]?.data?.items || [];
          const flatArray = items.map((item: any) => item.data.rows).flat()?.[0]?.[0];
          const title = flatArray?.find((row: any) => row.type === "staticLabel")?.title;
          const isTemplate3Item = isTemplate3(item.dynamicJson);
          return <div key={index} style={{
            marginBottom: "20px"
          }}>
                {title && <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              marginBottom: "5px"
            }}>
                    <Typography style={{
                fontWeight: "bold"
              }}>
                      {title}
                    </Typography>
                    <Typography>
                      Score: {parseFloat(item.score).toFixed(1)}
                    </Typography>
                  </div>}
                
                {isTemplate3Item ? renderTemplate3Data(item.scoreDynamicJson, item.dynamicJson) : <>
                    {item.comment && <Typography style={{
                marginLeft: "15px",
                marginBottom: "5px"
              }}>
                        {item.comment}
                      </Typography>}
                  </>}
              </div>;
        } catch (e) {
          console.error('Failed to parse rubric data:', e);
          return null;
        }
      })}
      </div>;
  };

  // Function to render rubric content
  const renderRubrics = (rubrics: RubricInfo.AsObject[]) => {
    return rubrics.map((rubric, index) => {
      // Check if this rubric contains template-4 data
      const hasTemplate4Data = rubric.rubricareainfoList.some(area => area.rubricareajsonandscoreList.some(item => {
        try {
          const dynamicJson = JSON.parse(item.dynamicJson);
          return dynamicJson[0]?.data?.items?.[0]?.templateName === 'template-4';
        } catch {
          return false;
        }
      }));
      if (hasTemplate4Data) {
        // Render template-4 format
        return <div key={index}>
            <Typography variant="h5" style={{
            marginBottom: '10px',
            textTransform: 'uppercase',
            fontWeight: 'bold'
          }}>
              {rubric.name}
            </Typography>
            {rubric.rubricareainfoList.map((area, areaIndex) => <div key={areaIndex}>
                {area.rubricareajsonandscoreList.map((item, itemIndex) => <div key={`item_${itemIndex}`}>
                    {item.scoreDynamicJson && renderTemplate4Table(item.scoreDynamicJson, item.comment)}
                  </div>)}
              </div>)}
          </div>;
      }

      // For non-template-4 rubrics
      return <React.Fragment key={`rubric_${index}`}>
          {rubric.rubricareainfoList.filter(({
          rubricareajsonandscoreList
        }) => rubricareajsonandscoreList.length > 0).map(({
          name: rubricAreaName,
          rubricareajsonandscoreList
        }, rubricAreaIndex) => renderRubricArea(rubricAreaName, rubricareajsonandscoreList, rubricAreaIndex))}
        </React.Fragment>;
    });
  };
  const notFoundData = () => {
    return <Grid container justify="center">
        <Typography variant="h2" style={{
        marginTop: "3rem"
      }}>
          {MESSAGES.filters}.
        </Typography>
      </Grid>;
  };

  // // Function to render score sheet in score sheet tab
  const renderScoreSheet = () => {
    const isVisibleRubric = enableRouting;
    const notFoundTeams = !filteredData?.length || filteredData?.every(({
      event
    }) => event?.teamScoreEventDivisionList.some(({
      teamScoreRankList
    }) => teamScoreRankList.some(({
      teamName,
      programName
    }) => teamName.length || programName.length)));
    return !notFoundTeams ? notFoundData() : (filteredData ?? []).map(({
      event
    }, index) => event?.teamScoreEventDivisionList.map(divisionInfo => divisionInfo?.teamScoreRankList.map((teamInfo, teamIndex) => {
      return <Grid container key={`score_sheet_${teamIndex}`} style={{
        padding: "14px"
      }}>
                  {index !== 0 ? <div style={{
          border: "2px solid",
          width: "100%",
          marginBottom: "29px"
        }} /> : null}
                  {renderHeader(event, divisionInfo, teamInfo)}
                  {isVisibleRubric ? renderRubricArea() : null}
                </Grid>;
    })));
  };

  // // Function to render score sheet in team score sheet tab
  const renderScoreSheetByTeam = () => {
    const notFound = !filteredEvents?.length;
    return notFound ? notFoundData() : filteredEvents?.map(({
      event
    }, index) => event?.teamScoreEventDivisionList.map(divisionInfo => divisionInfo.teamScoreRankList.map((teamInfo, teamIndex) => {
      return <Grid container key={`score_sheet_${teamIndex}`} style={{
        padding: "14px"
      }}>
                  {index !== 0 ? <div style={{
          border: "2px solid",
          width: "100%",
          marginBottom: "29px"
        }} /> : null}
                  {renderHeader(event, divisionInfo, teamInfo)}
                </Grid>;
    })));
  };

  // Instead of using state for teamTotalScores, let's calculate it directly
  const calculateTeamScores = useMemo(() => {
    if (!teamScoreInfo?.divisioninfo?.teamsinfoList) {
      return new Map<number, number>();
    }
    return new Map(teamScoreInfo.divisioninfo.teamsinfoList.map((teamInfo, index) => {
      const score = calculateTotalScore(teamInfo.rubricinfoList);
      console.log(`Calculating score for team ${index}:`, score);
      return [index, score];
    }));
  }, [teamScoreInfo]);

  // Add this new interface to help with day grouping
  interface DayGroup {
    date: string;
    teams: {
      teamInfo: any;
      event: any;
      divisionInfo: any;
      totalScore?: number;
    }[];
  }

  // Add this new function to group teams by performance date
  const groupTeamsByDay = (teams: any[], event: any, divisionInfo: any): DayGroup[] => {
    const dayGroups = new Map<string, DayGroup>();
    teams.forEach(teamInfo => {
      const performanceDate = teamInfo?.performanceDate?.formattedDateTime ? `${teamInfo.performanceDate.formattedDateTime.year}-${teamInfo.performanceDate.formattedDateTime.monthNumber}-${teamInfo.performanceDate.formattedDateTime.dayNumber}` : teamInfo.performanceDate || event?.firstDay || '';
      if (!dayGroups.has(performanceDate)) {
        dayGroups.set(performanceDate, {
          date: performanceDate,
          teams: []
        });
      }
      dayGroups.get(performanceDate)?.teams.push({
        teamInfo,
        event,
        divisionInfo
      });
    });
    return Array.from(dayGroups.values()).sort((a, b) => a.date.localeCompare(b.date));
  };

  // Move renderPerformanceRecap before it's used
  const renderPerformanceRecap = (event: Event.AsObject, divisionInfo: any, teamInfo: any, totalScore?: number) => {
    // Check if any rubric is template-4
    const hasTemplate4 = teamInfo.rubricinfoList?.some((rubric: any) => rubric.rubricareainfoList?.some((area: any) => area.rubricareajsonandscoreList?.some((item: any) => {
      try {
        const dynamicJson = JSON.parse(item.dynamicJson);
        return dynamicJson[0]?.data?.items?.[0]?.templateName === 'template-4';
      } catch {
        return false;
      }
    })));

    // Calculate rating if template-4 exists
    let rating = '';
    if (hasTemplate4) {
      const allRubricAreas = teamInfo.rubricinfoList?.flatMap((rubric: any) => rubric.rubricareainfoList?.flatMap((area: any) => area.rubricareajsonandscoreList || []) || []) || [];
      rating = calculateTeamTemplate4Rating(allRubricAreas);
    }
    return <div style={{
      marginBottom: '20px',
      borderBottom: '1px solid #000',
      paddingBottom: '10px'
    }}>
        <Typography variant="h6" style={{
        marginBottom: '10px',
        fontWeight: 'bold'
      }}>
          Performance Recap
        </Typography>
        <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}>
          <div>
            <Typography><strong>Event:</strong> {event?.brand?.name ?? 'N/A'} - {event?.name ?? 'N/A'}</Typography>
            <Typography><strong>Team:</strong> {teamInfo.programName} - {teamInfo.name || teamInfo.teamName}</Typography>
            <Typography><strong>Division:</strong> {divisionInfo?.division?.name ?? ''}</Typography>
            {hasTemplate4 ? <Typography><strong>Rating:</strong> {rating}</Typography> : totalScore !== undefined && totalScore > 0 && <Typography><strong>Total Score:</strong> {totalScore.toFixed(3)}</Typography>}
          </div>
          <img src={event?.logo || DEFAULT_IMAGE} alt="Event Logo" style={{
          height: "60px"
        }} />
        </div>
      </div>;
  };

  // Update the renderScoreSheetByTeamId function
  const renderScoreSheetByTeamId = () => {
    if (!teamScoreInfo?.divisioninfo?.teamsinfoList?.length) return null;
    return teamScoreInfo.divisioninfo.teamsinfoList.map((team, index) => {
      // Add day label at the start of each performance
      const dayLabel = teamScoreInfo.divisioninfo.teamsinfoList.length > 1 ? <Typography>
          Day {index + 1} Performance
        </Typography> : null;
      const rubricsList = team?.rubricinfoList || [];

      // Check if this is a template-4 team
      const hasTemplate4 = rubricsList.some(rubric => rubric.rubricareainfoList?.some(area => area.rubricareajsonandscoreList?.some(item => {
        try {
          const dynamicJson = JSON.parse(item.dynamicJson);
          return dynamicJson[0]?.data?.items?.[0]?.templateName === 'template-4';
        } catch {
          return false;
        }
      })));
      // Group scores by section with template-3 handling
      const sections = rubricsList.reduce((acc, rubric) => {
        if (!rubric?.rubricareainfoList) return acc;
        rubric.rubricareainfoList.forEach(area => {
          if (!area?.rubricareajsonandscoreList) return;

          // Track unique template-3 items by type
          const template3Totals = new Map();
          const template3Items = new Map();

          // Track template-1 items by title for potential merging
          const template1Items = new Map();
          area.rubricareajsonandscoreList.forEach(item => {
            try {
              if (!item?.dynamicJson) return;
              const dynamicJson = JSON.parse(item.dynamicJson);
              const sectionType = dynamicJson[0]?.data?.items?.[0]?.judgeType || 'Other';
              const templateName = dynamicJson[0]?.data?.items?.[0]?.templateName;
              const itemType = Object.keys(JSON.parse(item.scoreDynamicJson || '{}'))[0];
              if (!acc[sectionType]) {
                acc[sectionType] = {
                  items: [],
                  total: 0,
                  template3Items: new Map()
                };
              }
              if (templateName === 'template-3') {
                // Template-3 handling remains the same
                const currentScore = parseFloat(item.score || '0');
                template3Totals.set(itemType, (template3Totals.get(itemType) || 0) + currentScore);
                if (!template3Items.has(itemType)) {
                  template3Items.set(itemType, {
                    ...item,
                    score: template3Totals.get(itemType).toString()
                  });
                }
              } else if (templateName === 'template-1') {
                // Handle template-1 items
                const title = dynamicJson[0]?.data?.items?.[0]?.data?.rows?.[0]?.[0]?.title || '';
                const score = parseFloat(item.score || '0');
                const comment = item.comment || '';
                if (!template1Items.has(title)) {
                  // Create new array for this title
                  template1Items.set(title, []);
                }

                // Add this item to the array for its title
                template1Items.get(title).push({
                  ...item,
                  title,
                  score,
                  comment,
                  itemOrder: item.itemOrder,
                  templateName
                });
              } else {
                // Handle other template types normally
                acc[sectionType].items.push({
                  title: dynamicJson[0]?.data?.items?.[0]?.data?.rows?.[0]?.[0]?.title || '',
                  score: parseFloat(item.score || '0'),
                  comment: item.comment || '',
                  itemOrder: item.itemOrder,
                  templateName,
                  dynamicJson: item.dynamicJson,
                  scoreDynamicJson: item.scoreDynamicJson
                });
                acc[sectionType].total += parseFloat(item.score || '0');
              }
            } catch (e) {
              console.error('Failed to parse dynamic JSON:', e);
            }
          });

          // Add template-3 items with their totals
          template3Items.forEach((item, type) => {
            const sectionType = JSON.parse(item.dynamicJson)[0]?.data?.items?.[0]?.judgeType || 'Other';
            acc[sectionType].items.push({
              title: type,
              score: parseFloat(item.score || '0'),
              comment: item.comment || '',
              itemOrder: item.itemOrder,
              templateName: 'template-3',
              dynamicJson: item.dynamicJson,
              scoreDynamicJson: item.scoreDynamicJson
            });
            acc[sectionType].total += parseFloat(item.score || '0');
          });

          // Process template-1 items
          template1Items.forEach((items, title) => {
            const sectionType = JSON.parse(items[0].dynamicJson)[0]?.data?.items?.[0]?.judgeType || 'Other';

            // Calculate total score for these items
            const totalScore = items.reduce((sum, item) => sum + item.score, 0);
            if (totalScore === 0) {
              // If total is zero, add all items individually
              items.forEach(item => {
                acc[sectionType].items.push({
                  ...item,
                  title: item.title,
                  score: item.score,
                  comment: item.comment
                });
              });
            } else {
              // If total is non-zero, merge the items
              acc[sectionType].items.push({
                ...items[0],
                title,
                score: totalScore,
                comment: items.filter(item => item.comment).map(item => item.comment).join(' | ')
              });
              acc[sectionType].total += totalScore;
            }
          });
        });
        return acc;
      }, {} as Record<string, {
        items: Array<any>;
        total: number;
        template3Items: Map<string, any>;
      }>);
      if (hasTemplate4) {
        // For template-4, render the performance recap and template-4 tables
        return <div key={`score_sheet_${index}`} style={{
          padding: '20px'
        }}>
            {/* Performance Recap */}
            <div style={{
            marginBottom: '20px'
          }}>
              <Typography variant="h5" style={{
              fontWeight: 'bold',
              marginBottom: '10px'
            }}>
                Performance Recap
              </Typography>
              <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '20px',
              border: '1px solid black'
            }}>
                <div>
                  <Typography><strong>Event:</strong> {teamScoreInfo.eventinfo?.name}</Typography>
                  {dayLabel}
                  <Typography><strong>Team:</strong> {team.programName} - {team.name}</Typography>
                  <Typography><strong>Division:</strong> {teamScoreInfo.divisioninfo.division?.name}</Typography>
                  <Typography><strong>Rating:</strong> {calculateTeamTemplate4Rating(rubricsList.flatMap(rubric => rubric.rubricareainfoList?.flatMap(area => area.rubricareajsonandscoreList || []) || []))}</Typography>
                              
                </div>
                <img src={teamScoreInfo.eventinfo?.logo || DEFAULT_IMAGE} alt="Event Logo" style={{
                height: "80px"
              }} />
              </div>
            </div>

            
            {Object.entries(sections).filter(([_, section]) =>
          // Only show sections that have at least one template-3 item
          section.items.some(item => item.templateName === 'template-3')).map(([sectionName, section]) => <div key={sectionName} style={{
            marginBottom: '30px'
          }}>
              <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid #000',
              marginBottom: '15px'
            }}>
                <Typography variant="h6" style={{
                fontWeight: 'bold'
              }}>
                  {sectionName}
                </Typography>
                <Typography variant="h6" style={{
                fontWeight: 'bold'
              }}>
                  Section Total: {section.total.toFixed(1)}
                </Typography>
              </div>

              {section.items.sort((a, b) => a.itemOrder - b.itemOrder).map((item, idx) => item.templateName === 'template-3' ? <div key={idx} style={{
              marginBottom: '15px',
              display: 'flex',
              justifyContent: 'space-between'
            }}>
                    <div style={{
                flex: 1
              }}>
                      {item.templateName === 'template-3' ? renderTemplate3Data(item.scoreDynamicJson, item.dynamicJson) : <>
                          <Typography style={{
                    fontWeight: 'bold'
                  }}>
                            {item.title}
                          </Typography>
                          {item.comment && <Typography style={{
                    marginTop: '5px',
                    marginLeft: '15px'
                  }}>
                              {item.comment}
                            </Typography>}
                        </>}
                    </div>
                    <Typography style={{
                marginLeft: '20px',
                minWidth: '80px',
                textAlign: 'right'
              }}>
                      Score: {item.score.toFixed(1)}
                    </Typography>
                  </div> : null)}
              </div>)}
            {/* Template-4 Tables */}
            {rubricsList.map((rubric, rubricIndex) => <div key={`rubric_${rubricIndex}`}>
                <Typography variant="h6" style={{
              marginTop: '20px',
              marginBottom: '10px',
              fontWeight: 'bold'
            }}>
                  {rubric.name}
                </Typography>
                {rubric.rubricareainfoList?.map((area, areaIndex) => <div key={`area_${areaIndex}`}>
                    {area.rubricareajsonandscoreList?.map((item, itemIndex) => <div key={`item_${itemIndex}`}>
                        {item.scoreDynamicJson && renderTemplate4Table(item.scoreDynamicJson, item.comment)}
                      </div>)}
                  </div>)}
              </div>)}
          </div>;
      }
      return <div key={`score_sheet_${index}`} style={{
        padding: '20px'
      }}>
          {/* Performance Recap with Logo */}
          <div style={{
          marginBottom: '20px'
        }}>
            <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: '20px'
          }}>
              <div>
                <Typography variant="h5" style={{
                fontWeight: 'bold',
                marginBottom: '10px'
              }}>
                  Performance Recap
                </Typography>
                <Typography>Event: {teamScoreInfo.eventinfo?.name}</Typography>
                {dayLabel}
                <Typography>Team: {team.programName} - {team.name}</Typography>
                <Typography>Division: {teamScoreInfo.divisioninfo.division?.name}</Typography>
                <Typography>Total Score: {Object.values(sections).reduce((sum, section) => sum + section.total, 0).toFixed(1)}</Typography>
                       
              </div>
              <img src={teamScoreInfo.eventinfo?.logo || DEFAULT_IMAGE} alt="Event Logo" style={{
              height: '80px'
            }} />
            </div>
          </div>

          {/* Render each section */}
          {Object.entries(sections).map(([sectionName, section]) => <div key={sectionName} style={{
          marginBottom: '30px'
        }}>
              <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #000',
            marginBottom: '15px'
          }}>
                <Typography variant="h6" style={{
              fontWeight: 'bold'
            }}>
                  {sectionName}
                </Typography>
                <Typography variant="h6" style={{
              fontWeight: 'bold'
            }}>
                  Section Total: {section.total.toFixed(1)}
                </Typography>
              </div>

              {section.items.sort((a, b) => a.itemOrder - b.itemOrder).map((item, idx) => <div key={idx} style={{
            marginBottom: '15px',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
                    <div style={{
              flex: 1
            }}>
                      {item.templateName === 'template-3' ? renderTemplate3Data(item.scoreDynamicJson, item.dynamicJson) : <>
                          <Typography style={{
                  fontWeight: 'bold'
                }}>
                            {item.title}
                          </Typography>
                          {item.comment && <Typography style={{
                  marginTop: '5px',
                  marginLeft: '15px'
                }}>
                              {item.comment}
                            </Typography>}
                        </>}
                    </div>
                    <Typography style={{
              marginLeft: '20px',
              minWidth: '80px',
              textAlign: 'right'
            }}>
                      Score: {item.score.toFixed(1)}
                    </Typography>
                  </div>)}
            </div>)}
        </div>;
    });
  };

  // Function to render loading content
  const renderLoader = () => {
    return <Grid style={{
      justifyContent: "center"
    }}>
        <Box style={{
        ...LOADING_OR_ERROR_CONTENT_STYLE
      }}>
          <Loader height="2%" width="2%" />
          <Typography style={{
          marginLeft: "20px",
          marginTop: "2px"
        }}>
            {MESSAGES.loading}.....
          </Typography>
        </Box>
      </Grid>;
  };

  // Function to render errorMessage content
  const errorMessageContent = () => {
    return <Grid style={{
      justifyContent: "center"
    }}>
        <Box style={{
        ...LOADING_OR_ERROR_CONTENT_STYLE
      }}>
              <Typography variant="h2" style={{
          marginLeft: "20px",
          marginTop: "2px",
          textTransform: "capitalize"
        }}>
            {errorMessage}.....
              </Typography>
        </Box>
      </Grid>;
  };

  // Render score sheet component on conditional based
  const renderScoreSheetComponent = () => {
    let scoreSheetComponent;
    if (teamId) {
      scoreSheetComponent = renderScoreSheetByTeam();
      console.log("scoresheet by team");
    } else if (enableRouting && eventDivisionTeam) {
      scoreSheetComponent = renderScoreSheetByTeamId();
      console.log("scoresheet by team id");
    } else {
      scoreSheetComponent = renderScoreSheet();
      console.log("scoresheet");
    }
    return <div className="print-only">
        {scoreSheetComponent}
      </div>;
  };

  // Render content based on conditional
  const renderContent = () => {
    let content;
    if (isLoading) {
      content = renderLoader();
    } else if (errorMessage) {
      content = errorMessageContent();
    } else {
      content = renderScoreSheetComponent();
    }
    return content;
  };

  // Update print styles
  const printStyles = `
    @media print {
      @page {
        margin: 0.5in;
      }
      
      body * {
        visibility: hidden;
      }
      
      .print-only, .print-only * {
        visibility: visible !important;
      }
      
      .print-only {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
      
      /* Keep first section content together */
      section > div:first-child {
        break-inside: avoid-page;
        page-break-inside: avoid;
      }
      
      /* Performance recap should appear at top of each page */
      section > div:not(:first-child) {
        break-before: page;
      }
      
      h4, h5, h6 {
        margin-top: 0;
        page-break-after: avoid;
      }
      
      img {
        max-width: 100px;
        height: auto;
      }
    }
  `;
  return <>
      <style>{printStyles}</style>
      <div className="print-only">
        {renderContent()}
      </div>
    </>;
};
export default ScoreSheet;